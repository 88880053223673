import React from "react";
import CompareProducts from "../components/partials/compare-products";
import GoogleReviews from "../components/sections/google-reviews";

const CompareCovers = () => {
  return (
    <>
      <CompareProducts />
      <GoogleReviews />
    </>
  );
};

export default CompareCovers;
