import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { styles } from "../../utilities/styles";

const GoogleReviews = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allWpReview(sort: { fields: [date], order: DESC }, limit: 3) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              content
              review {
                rating
              }
            }
          }
        }
      }
    `
  );

  return (
    <section className={classNames("relative overflow-hidden", className)}>
      <div className="absolute inset-0">
        <StaticImage
          src="../../images/home-page/poly-6.jpg"
          alt="Safe and secure window well covers"
          objectFit="cover"
          objectPosition="50% 50%"
          className="w-full h-full"
        />
      </div>
      <div className="relative w-full py-16">
        <div className="max-w-4xl mx-auto block mb-8 lg:px-24">
          <h2 className={classNames(styles.h2, "text-white text-center py-8")}>
            <a
              href={process.env.GOOGLE_REVIEWS_PAGE}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="text-white no-underline"
            >
              Google Reviews
            </a>
          </h2>

          <div className="block md:grid md:grid-cols-3 gap-4 items-start px-4">
            {data.allWpReview.edges.map(({ node }) => (
              <div
                key={node.slug}
                className="px-6 py-8 mb-12 lg:mb-0 bg-white rounded-lg text-grey-darker"
              >
                <a
                  aria-label="5 Star Google Review"
                  href={process.env.GOOGLE_REVIEWS_PAGE}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="no-underline flex w-32"
                  title="5 Star Google Review"
                >
                  <MdOutlineStarPurple500 className="text-[#EDBD31] w-12 h-12" />
                  <MdOutlineStarPurple500 className="text-[#EDBD31] w-12 h-12" />
                  <MdOutlineStarPurple500 className="text-[#EDBD31] w-12 h-12" />
                  <MdOutlineStarPurple500 className="text-[#EDBD31] w-12 h-12" />
                  <MdOutlineStarPurple500 className="text-[#EDBD31] w-12 h-12" />
                </a>
                <div className="leading-loose mb-6">
                  <div
                    className="no-underline"
                    dangerouslySetInnerHTML={{ __html: node.content }}
                  />
                </div>
                <p className="">
                  <a
                    href={process.env.GOOGLE_REVIEWS_PAGE}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="underline text-grey-darker"
                  >
                    {node.title}
                  </a>
                </p>
              </div>
            ))}
          </div>
          <div className="container m-auto mt-8">
            <a
              href={process.env.GOOGLE_REVIEWS_PAGE}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="block text-center uppercase text-white text-sm no-underline"
            >
              See all of our reviews
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoogleReviews;
